export const environment = {
  production: true,
  apiUrl: "https://app.springbot.com",
  heapApplicationId: "2120011083",
  gaTrackingId: "G-DG14MYCDSM",
  reCAPTCHASiteKey: "6LcTkfoSAAAAANAnruAhlpJIlMgeQ3Qvkpiu3Jws",
  intercomAppId: "t0oty1ty",
  bugsnagApiKey: "557aa80e7554505d8de94f52ae4a294a",
  appVersion: require('../../package.json').version,
};
