import { Component, Input, OnChanges, SimpleChanges, OnInit, ChangeDetectorRef } from "@angular/core";
import { ChannelsService } from "@app/shared/services/channels.service";
import { CsvService } from "@app/shared/services/csv.service";
import { StoreService } from "@app/shared/services/store.service";
import { Channel } from "@models/channel";
import { ChannelsFilter } from "@models/channels_filter";
import { Store } from "@models/store";
import { ReportingUtilityService } from "@app/reports/services/reports/reporting_utility.service";
import { Router } from "@angular/router";

@Component({
  selector: "channels-index-component",
  templateUrl: "./channels_index.component.html",
  styleUrls: ["./channels_index.component.scss"],
})
export class ChannelsIndexComponent implements OnChanges, OnInit {
  @Input()
  embedded: boolean = false;

  @Input()
  datesFilter: any;

  store: Store;
  channels: Channel[];
  loading: boolean;
  showBreadcrumbs: boolean;
  actionPopoverText: string = "";
  filter = new ChannelsFilter({
    endDate: new Date(),
    page: 1,
    perPage: 10,
    sort: "created_at desc",
    startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
  });

  constructor(
    private storeService: StoreService,
    private channelsService: ChannelsService,
    private reportingUtilityService: ReportingUtilityService,
    private csvService: CsvService,
    private router: Router,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.store = this.storeService.getSource().value;
    this.showBreadcrumbs = this.router.url == "/performance/channels";
    if (!this.datesFilter?.startDate) {
      this.getChannels();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.datesFilter) {
      this.datesChanged(changes.datesFilter);
    }
  }

  datesChanged(dates) {
    const currentDates = dates.currentValue;
    if (currentDates?.startDate && currentDates?.endDate) {
      this.filter.startDate = new Date(currentDates.startDate);
      this.filter.endDate = new Date(currentDates.endDate);
      this.getChannels();
    }
  }

  getChannels() {
    this.loading = true;

    this.channelsService.dashboardStats({
      startDate: this.filter.startDate,
      endDate: this.filter.endDate,
      only_active_channels: true,
    }).subscribe((response) => {
      this.channels = response.data
      this.loading = false;
      this.ref.detectChanges();
    });
  }

  sortBy(sort) {
    const by = sort.split(" ")[0];
    const direction = sort.split(" ")[1];
    const channelsSorted = this.channels.sort((a, b) => {
      if (by === "displayName") {
        const fa = a.displayName.toLowerCase();
        const fb = b.displayName.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      }
      return a.stats[by] - b.stats[by];
    });

    this.channels = direction === "desc" ? channelsSorted.reverse() : channelsSorted;
  }

  getIcon(channel): string[] {
    return this.reportingUtilityService.getChannelIcon(channel);
  }

  getCsv() {
    const data = this.channels.map((channel) => {
      return {
        ...{
          name: channel.displayName,
           createdAt:channel.createdAt
        },
        ...channel.stats
      }
    });
    this.csvService.downloadCsv<any>(data, "Channels");
  }
}
