import { Component, TemplateRef, ViewChild } from "@angular/core";
import {
  ProductSelectModalComponent,
} from "@app/shared/components/product_select_modal/product_select_modal.component";
import { S3UploadApiService } from "@app/shared/services/s3_upload_api.service";
import { TwitterLocationsComponent } from "@app/social/components/twitter_locations/twitter_locations.component";
import { SocialBlastService } from "@app/social/services/social_blast.service";
import { SocialBlastAttachmentService } from "@app/social/services/social_blast_attachment_service";
import { Product } from "@models/product";
import { FacebookRestrictions } from "@models/social/facebook_restrictions";
import { SocialAccount } from "@models/social/social_account";
import { SocialBlast } from "@models/social/social_blast";
import { SocialBlastAttachment } from "@models/social/social_blast_attachment";
import { SocialBlastParams } from "@models/social/social_blast_params";
import { NgbActiveModal, NgbModal, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import {
  interval, Observable, from, iif, of,
} from "rxjs";
import { mergeMap, take } from "rxjs/operators";

import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DateParserFormatterService } from "@app/shared/services/date_parser_formatter.service";
import {
  AIModuleModalService
} from "@app/content_editor/components/email_content_editor/services/ai_module_modal.service";
import { GeneratedText } from "@models/generated_text";
import { TextGeneratorService } from "@app/shared/services/text_generator_service";
import { UniversalErrorService } from "@app/shared/services/universal_error_service";


// IMPORTANT!!
// to edit a socialBlast leave the id's of the socialBlast AND attachments in the socialBlast Input
// to create a new socialBlast from an existing one
// - strip the ids out of both the socialBlast AND the socialBlastAttachments
// SocialBlastService#clonePostingToAccount
// - is used to prepare data for cloning or editing a SocialBlast to target a single account
@Component({
  selector: "create-post-modal",
  styleUrls: ["./create_post_modal.component.scss"],
  templateUrl: "./create_post_modal.component.html",
  providers: [
    {provide: NgbDateParserFormatter, useClass: DateParserFormatterService},
  ],
})
export class CreatePostModalComponent {
  @ViewChild("datePicker", {static: false}) datePicker;
  @ViewChild('linkVerify') linkVerifyModal: TemplateRef<HTMLElement>;
  posting = false;
  contentLength = 0;
  startDateMinDate;
  pickerDatetime: any;
  pickerTime = {} as NgbTimeStruct;
  postDatetime: Date;
  socialAccounts: SocialAccount[] = []; // set via SocialAccountSelector
  socialBlast: SocialBlast; // holds incoming social blast for update OR duplication
  socialBlastUpdateId: string;
  socialBlastParams: SocialBlastParams = {
    productId: null,
    content: "",
    useProviderFacebook: false,
    useProviderTwitter: false,
    useProviderInstagram: false,
    socialBlastAttachmentsAttributes: [],
    postDatetime: null,
    facebookPageIds: [],
    twitterUserIds: [],
    instagramAccountIds: [],
    twitterPlaceId: null,
  };

  // primary accounts set via SocialAccountSelector
  primaryFacebookAccount: SocialAccount;
  primaryTwitterAccount: SocialAccount;
  primaryInstagramAccount: SocialAccount;
  maxPostLength = 63206; // defaults to facebook max, which is the largest
  attachedFiles: SocialBlastAttachment[] = []; // holds files in flat array for display
  errors: string[] = [];
  postSuccess = false;
  croppingWarning = true;
  productLinkInstructions = false;
  loading = true;

  generatedContent: GeneratedText;
  generatingContent = false;
  generatingAiContent = false;
  generatedContentPoll: any;
  aiTookForever: boolean = false;
  aiDesignerEnabled: boolean = false;

  // facebook targeting fields
  facebookRestrictions: FacebookRestrictions = {
    ageMin: null,
    countries: [],
    regions: [],
    cities: [],
    zips: [],
  };

  selectedTwitterPlace: { id: string, name: string };
  twitterAuthed: boolean;
  assetLoadProgress = 0;
  private product: Product;

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private socialBlastService: SocialBlastService,
    private socialBlastAttachmentService: SocialBlastAttachmentService,
    private s3UploadApiService: S3UploadApiService,
    private aiModuleModalService: AIModuleModalService,
    private textGeneratorService: TextGeneratorService,
    private errorService: UniversalErrorService,
  ) {
  }

  ngOnInit(): void {
    if (this.socialBlast) {
      this.initFromSocialBlast();
    }

    const startDateMinDate = new Date();
    startDateMinDate.setDate(new Date().getDate() + 1);
    this.startDateMinDate = {
      year: startDateMinDate.getFullYear(),
      month: startDateMinDate.getMonth() + 1,
      day: startDateMinDate.getDate()
    };
  }

  openAIDesigner() {
    this.aiModuleModalService.openSocialModal().then((generatedContent) => {
      if (generatedContent) {
        this.aiTookForever = false;
        this.generatingAiContent = true;
        this.generatedContent = generatedContent;
        const selectedProduct = generatedContent.selectedProduct;
        this.generatedContentPoll = interval(5000).subscribe(() => {
          this.pollGeneratedContent(selectedProduct);
        });
        setTimeout(() => {
          if (this.generatingAiContent) {
            this.aiTookForever = true;
            this.generatingAiContent = false;
            this.generatedContentPoll?.unsubscribe();
            setTimeout(() => {
              this.aiTookForever = false;
            }, 4000);
            console.log(`Timeout. Generated MJML Worker Id: ${this.generatedContent.id }`);
          }
        }, 80000);
      }
    });
  }

  pollGeneratedContent(selectedProduct = null): void {
    this.textGeneratorService.show(this.generatedContent.id).subscribe((generatedContent) => {
      this.generatedContent = generatedContent;
      if (generatedContent.status == 'finished') {
        this.generatedContentPoll?.unsubscribe();
        this.generatingAiContent = false;
        this.socialBlastParams.content = "";
        this.deleteAttachedProduct();
        setTimeout(() => {
          if (selectedProduct) {
            this.updateAttachedProduct(selectedProduct, false);
          }
          if (this.generatedContent.generatedText[0]) {
            this.socialBlastParams.content = this.generatedContent.generatedText[0];
          }
          this.calculateContentLength();
        }, 500);
      } else if (generatedContent.status == 'errored') {
        this.generatedContentPoll?.unsubscribe();
        this.generatingAiContent = false;
        console.log('An error occurred generating the template. Code: ' + this.generatedContent.id.toString());
      }
    });
  }

  onDatetimeChange(event): void {
    this.pickerDatetime = new Date(event.year, event.month - 1, event.day, this.pickerTime?.hour || 10, this.pickerTime?.minute || 0, 0);
    this.postDatetime = this.pickerDatetime;
    this.socialBlastParams.postDatetime = this.pickerDatetime;
  }

  onPickerTimeChange(event): void {
    const date = new Date(this.pickerDatetime);
    this.pickerDatetime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), this.pickerTime.hour, this.pickerTime.minute, 0);
    this.postDatetime = this.pickerDatetime;
    this.socialBlastParams.postDatetime = this.pickerDatetime;
  }

  // using data from SocialBlast input
  // - updates SocialBlastParms for post/update
  // - sets all needed local data for sub-components
  initFromSocialBlast(): void {
    this.setParamsAndAttachmentsFromSocialBlast();
    this.setFacebookRestrictionsFromSocialBlast();
    this.setPostDatetimeFromSocialBlast();
  }

  // clears ids out of data for post and update
  // if socialBlast is passed in with an id it UPDATES
  setParamsAndAttachmentsFromSocialBlast(): void {
    const {id, socialBlastAttachments, ...socialBlastParams} = this.socialBlast;
    this.socialBlastParams = socialBlastParams;
    this.socialBlastUpdateId = id;
    if (socialBlastAttachments?.length > 0) {
      this.socialBlastParams.socialBlastAttachmentsAttributes = socialBlastAttachments;
      this.attachedFiles = [...socialBlastAttachments];
    }
    if (this.socialBlastParams.userSubmittedVideoFileName) {
      this.attachedFiles.push(this.getVideoFromSocialBlast());
    }
  }

  getVideoFromSocialBlast(): SocialBlastAttachment {
    return {
      fileName: this.socialBlast.userSubmittedVideoFileName,
      imageFileName: this.socialBlast.userSubmittedVideoFileName,
      imageContentType: this.socialBlast.userSubmittedVideoContentType,
      imageFileSize: this.socialBlast.userSubmittedVideoFileSize,
      imageUpdatedAt: this.socialBlast.userSubmittedVideoUpdatedAt,
      altText: null,
      imageUrl: null,
      useProviderFacebook: this.socialBlast.useProviderFacebook,
      useProviderTwitter: this.socialBlast.useProviderTwitter,
      useProviderInstagram: this.socialBlast.useProviderInstagram,
    };
  }

  setFacebookRestrictionsFromSocialBlast(): void {
    this.facebookRestrictions = {
      ageMin: this.socialBlast.facebookTargetingAgeMin,
      countries: this.socialBlast.facebookTargetingCountries || [],
      regions: this.socialBlast.facebookTargetingRegions || [],
      cities: this.socialBlast.facebookTargetingCities || [],
      zips: this.socialBlast.facebookTargetingZips || [],
    };
  }

  // converts the socialBlast.postDatetime timestamp string into Date object
  setPostDatetimeFromSocialBlast(): void {
    this.postDatetime = new Date(this.socialBlast.postDatetime);
    this.pickerDatetime = this.postDatetime;
  }

  close(): void {
    this.modalService.dismissAll();
  }

  postOrUpdate(): void {
    this.posting = true;
    const $validateData = this.validateData().pipe(take(1));
    const $skipValidateData = of("skipForLinkPlacement");
    this.validateProductLink().pipe(
      take(1),
      mergeMap((linkResult) => iif(() => linkResult === "ok",
        $validateData,
        $skipValidateData)),
    ).subscribe((result: boolean | string = "skipForLinkPlacement") => {
      if (result === "skipForLinkPlacement") {
        this.posting = false;
      } else if (this.socialBlastUpdateId) {
        this.update();
      } else {
        this.post();
      }
    }, (response) => {
      this.handleErrorResponse(response);
    });
  }

  post(): void {
    this.socialBlastService.post(this.socialBlastParams).subscribe((createdSocialBlast) => {
      this.postSuccess = true;
      setTimeout(() => this.activeModal.close({actionTaken: "create", socialBlast: createdSocialBlast}), 1000);
    }, (response) => {
      this.handleErrorResponse(response);
    });
  }

  update(): void {
    this.socialBlastService.update(
      this.socialBlastUpdateId,
      this.socialBlastParams,
    ).subscribe((updatedSocialBlast) => {
      this.postSuccess = true;
      setTimeout(() => this.activeModal.close({actionTaken: "update", socialBlast: updatedSocialBlast}), 1000);
    }, (response) => {
      this.handleErrorResponse(response);
    });
  }

  // somewhat hacky way to get calendar to close and update
  onPostDatetimeChange(event): void {
    if (event._value) {
      this.datePicker.close();
      this.postDatetime = this.pickerDatetime;
      this.socialBlastParams.postDatetime = this.pickerDatetime;
      this.pickerDatetime = null;
    }
  }

  deleteAttachment(index: number): void {
    // remove from attachedFiles array
    const fileToRemove = this.attachedFiles.splice(index, 1)[0];
    // remove video data from socialBlastParams
    if (fileToRemove.imageContentType.includes("video")) {
      this.socialBlastParams.userSubmittedVideoContentType = null;
      this.socialBlastParams.userSubmittedVideoFileName = null;
      this.socialBlastParams.userSubmittedVideoFileSize = null;
      this.socialBlastParams.userSubmittedVideoUpdatedAt = null;
      return;
    }
    // clear the associate link out of the content for products, clears all product data
    if (fileToRemove.productId) {
      this.removeProductLink(fileToRemove.productLandingUrl);
      this.product = null;
      this.socialBlastParams.productId = null;
    }
    const attachmentIndex = (
      this.socialBlastParams.socialBlastAttachmentsAttributes.findIndex(
        (attachment) => attachment.imageFileName === fileToRemove.imageFileName,
      )
    );
    const attachmentToRemove = (
      this.socialBlastParams.socialBlastAttachmentsAttributes[attachmentIndex]
    );
    if (attachmentToRemove.id) { // if already saved to backend set to destroy
      attachmentToRemove._destroy = true;
    } else { // if not saved just remove it
      this.socialBlastParams.socialBlastAttachmentsAttributes.splice(attachmentIndex, 1);
    }
  }

  addAttachment(attachment: SocialBlastAttachment): void {
    this.attachedFiles.push(attachment); // flat array of files for display in modal

    // actual post/put params to send to backend,
    // video is attached in a different manner than images
    if (attachment.imageContentType.includes("video")) {
      this.socialBlastParams.userSubmittedVideoFileName = attachment.imageFileName;
      this.socialBlastParams.userSubmittedVideoContentType = attachment.imageContentType;
      this.socialBlastParams.userSubmittedVideoFileSize = attachment.imageFileSize;
      this.socialBlastParams.userSubmittedVideoUpdatedAt = attachment.imageUpdatedAt;
    } else {
      this.socialBlastParams.socialBlastAttachmentsAttributes.push({
        ...(attachment),
        useProviderFacebook: this.socialBlastParams.useProviderFacebook,
        useProviderTwitter: this.socialBlastParams.useProviderTwitter,
        useProviderInstagram: this.socialBlastParams.useProviderInstagram,
      });
    }
  }

  onUpload(files: FileList): void {
    const file: File = files[0];
    if (!file) {
      this.displayErrors("Please retry, image failed to attach.");
      return;
    }
    const progressTimer = this.progressTimer();
    this.validateFileForAttachment(file).pipe(
      take(1),
      mergeMap(() => this.s3UploadApiService.uploadFile(file, "sh", "public-read")),
    ).subscribe((data) => {
      const s3ImageUrl = data.url;
      this.completeProgressTimer(progressTimer);
      this.addAttachment({
        // original name of file, not saved to database TODO: persist this to the database
        fileName: file.name,
        imageUrl: s3ImageUrl, // for display, not saved to database
        altText: "",
        imageFileName: /social_hub\/.*/.exec(s3ImageUrl)[0],
        imageFileSize: file.size,
        imageContentType: file.type,
        imageUpdatedAt: new Date().toUTCString(),
      });
    }, (response) => {
      this.killProgressTimer(progressTimer);
      this.handleErrorResponse(response);
    });
  }

  setMaxPostLength(): void {
    if (this.socialBlastParams.useProviderTwitter) {
      this.maxPostLength = 280;
    } else if (this.socialBlastParams.useProviderInstagram) {
      this.maxPostLength = 2200;
    } else {
      this.maxPostLength = 63206;
    }
  }

  openProductSelectModal(): void {
    const hasNonProductAttachments = (
      this.attachedFiles.filter(({productId}) => !productId).length !== 0
    );
    if (hasNonProductAttachments) {
      this.displayErrors("Linked products cannot be added with other attachments");
      return;
    }
    const modalRef = this.modalService.open(ProductSelectModalComponent);
    if (this.product) {
      modalRef.componentInstance.selectedProduct = this.product;
    } else if (this.socialBlastParams.productId) {
      modalRef.componentInstance.selectedProductId = this.socialBlastParams.productId;
    }
    modalRef.result.then((product: Product) => this.updateAttachedProduct(product));
  }

  // for a linked product to work correctly,
  // - the landing url is inserted into the post content
  // as plain text (social platforms will "understand" as a link)
  // - product id is saved to the social blast
  // - product attachment is attached
  // (serves primarily to sync data for editing existing scheduled posts)
  updateAttachedProduct(product: Product, updateContent = true): void {
    if (product.id === this.socialBlastParams.productId) {
      return;
    }
    this.socialBlastService.getProductImageSize(product.id,
      product.imageUrl || product.childImageUrls[0]).subscribe((response) => {
      const fileSize = response.image_size;
      this.validateProductImage(fileSize);
      if (this.errors.length > 0) {
        return;
      }
      this.deleteAttachedProduct(); // clear previous attachment if present
      this.product = product;
      this.socialBlastParams.productId = product.id;
      if (updateContent) {
        this.socialBlastParams.content += product.landingUrl;
      }
      this.addAttachment({
        fileName: product.name,
        imageUrl: product.imageUrl || product.childImageUrls[0], // local only, not saved to database
        imageFileName: null,
        imageFileSize: fileSize,
        imageUpdatedAt: null,
        productId: product.id,
        imageContentType: "product_image",
        productUrl: product.imageUrl || product.childImageUrls[0],
        productLandingUrl: product.landingUrl,
        displayProductImageLink: true, // defaults to displaying the link in the text
        altText: "",
      });
    }, (_error) => {
      this.displayErrors("Something went wrong while trying to fetch product image...");
    });
  }

  openTwitterLocationsModal(): void {
    const modalRef = this.modalService.open(TwitterLocationsComponent, {windowClass: "customClass"});
    modalRef.componentInstance.selectedPlaceId = this.socialBlastParams.twitterPlaceId;
    modalRef.componentInstance.selectedPlace = this.selectedTwitterPlace;
    modalRef.componentInstance.twitterAuthed = this.twitterAuthed;

    // the result can be null which removes the selectedTwitterPlace
    modalRef.result.then((selectedTwitterPlace) => {
      this.selectedTwitterPlace = selectedTwitterPlace;
      this.socialBlastParams.twitterPlaceId = selectedTwitterPlace?.id;
    });
  }

  // on first load when a source SocialBlast is present,
  // the selected SocialAccounts are updated off the SocialBlast
  // otherwise the post/put params are updated off the selected socialAccounts
  onSelectedAccountsChange(socialAccounts: SocialAccount[]): void {
    this.socialAccounts = socialAccounts;
    if (this.loading && this.socialBlast) {
      this.setSelectedAccountsFromSocialBlast();
    } else {
      this.updateSelectedAccountParams();
    }
    this.twitterAuthed = this.socialAccounts.some((account) => account.platform === "twitter");
    this.loading = false;
    this.setMaxPostLength();
  }

  // returns the facebook page data for the preview, usually it's the primary page
  facebookPreviewAccount(): SocialAccount {
    if (this.primaryFacebookAccount?.selected) {
      return this.primaryFacebookAccount;
    }
    return this.socialAccounts.find(({platform, selected}) => selected && platform === "facebook");
  }

  // updates the post/put params on changes in the FacebookRestrictionsComponent
  updateFbRestrictionParams(updatedFbRestrictions: FacebookRestrictions): void {
    this.facebookRestrictions = updatedFbRestrictions;
    this.socialBlastParams.facebookTargetingAgeMin = this.facebookRestrictions.ageMin;
    this.socialBlastParams.facebookTargetingCountries = this.facebookRestrictions.countries;
    this.socialBlastParams.facebookTargetingRegions = this.facebookRestrictions.regions;
    this.socialBlastParams.facebookTargetingCities = this.facebookRestrictions.cities;
    this.socialBlastParams.facebookTargetingZips = this.facebookRestrictions.zips;
  }

  setSelectedAccountsFromSocialBlast(): void {
    const {facebookPageIds, instagramAccountIds, twitterUserIds} = this.socialBlast;
    const selectedIds = [...facebookPageIds, ...instagramAccountIds, ...twitterUserIds];
    this.socialAccounts.forEach((account) => account.selected = selectedIds.includes(account.id));
  }

  updateSelectedAccountParams(): void {
    const {facebook, twitter, instagram} = this.selectedAccountIdsByPlatform();
    this.socialBlastParams.facebookPageIds = facebook;
    this.socialBlastParams.twitterUserIds = twitter;
    this.socialBlastParams.instagramAccountIds = instagram;
    this.socialBlastParams.useProviderFacebook = facebook.length > 0;
    this.socialBlastParams.useProviderTwitter = twitter.length > 0;
    this.socialBlastParams.useProviderInstagram = instagram.length > 0;
  }

  selectedAccountIdsByPlatform(): { facebook: string[], twitter: string[], instagram: string[] } {
    return this.socialAccounts.filter(({selected}) => selected)
      .reduce((result, account) => {
        result[account.platform].push(account.id);
        return result;
      }, {facebook: [], twitter: [], instagram: []});
  }

  onToggleProductLink(displayLink: boolean): void {
    displayLink ? this.addProductLink() : this.removeProductLink();
  }

  // if the link is missing a modal will open verifying if the link removal was intentional
  validateProductLink(): Observable<"reinsert" | "ok"> {
    if (!this.isProductLinkMissing()) {
      return new Observable((subscriber) => subscriber.next("ok"));
    }
    const modalResult = this.modalService.open(this.linkVerifyModal).result.then((result: "ok" | "reinsert") => {
      if (result === "reinsert") {
        this.addProductLink();
        this.productLinkInstructions = true;
      } else {
        this.getLinkedProduct().displayProductImageLink = false;
      }
      return result;
      // eslint-disable-next-line prefer-promise-reject-errors
    }, (_error) => Promise.reject("Product link is missing")); // unifies error messages for dismissals
    return from(modalResult);
  }

  calculateContentLength(): void {
    if (this.socialBlastParams.useProviderTwitter) {
      // twitter counts 23 characters in URLs even if they have less or more characters
      // https://help.twitter.com/en/using-twitter/how-to-tweet-a-link
      const urlRegEx = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      const urls = this.socialBlastParams.content.match(urlRegEx) || [];
      const urlsLength = urls.length * 23;
      const contentWithoutUrls = this.socialBlastParams.content.replace(urlRegEx, "");
      this.contentLength = contentWithoutUrls.length + urlsLength;
    } else {
      this.contentLength = this.socialBlastParams.content.length;
    }
  }

  private validateData(): Observable<boolean> {
    const errors = this.getDataErrors();
    return new Observable((subscriber) => {
      if (this.attachedFiles.length === 0) {
        errors.length > 0 ? subscriber.error(errors) : subscriber.next(true);
        subscriber.complete();
      } else {
        this.validateAttachments().pipe(take(1)).subscribe(() => {
          errors.length > 0 ? subscriber.error(errors) : subscriber.next(true);
          subscriber.complete();
        }, (attachmentErrors) => {
          subscriber.error([...errors, ...attachmentErrors]);
          subscriber.complete();
        });
      }
    });
  }

  private getDataErrors(): string[] {
    const errors = [];
    if (!this.hasSelectedAnAccount()) {
      errors.push("No social network selected");
    }
    if (this.postDatetime && this.postDatetime <= new Date()) {
      errors.push("Post time cannot be in the past");
    }
    if (this.socialBlastParams.content.length === 0) {
      errors.push("Post content cannot be empty");
    }
    if (this.attachedFiles.length === 0 && this.socialBlastParams.useProviderInstagram) {
      errors.push("Instagram Posts must have a media attachment");
    }
    return errors;
  }

  private validateAttachments(): Observable<boolean> {
    return this.socialBlastAttachmentService.validateAll(
      this.attachedFiles,
      this.getSocialToValidate(),
    );
  }

  private isProductLinkMissing(): boolean {
    if (!this.socialBlastParams.productId) {
      return false;
    }
    const {productLandingUrl, displayProductImageLink} = this.getLinkedProduct();
    if (!displayProductImageLink) {
      return false;
    }
    return !this.socialBlastParams.content.includes(productLandingUrl);
  }

  private addProductLink(): void {
    const {productLandingUrl} = this.getLinkedProduct();
    if (this.isProductLinkMissing() && productLandingUrl) {
      this.socialBlastParams.content = `${this.socialBlastParams.content.trimRight()} ${productLandingUrl}`;
    }
  }

  private removeProductLink(productLandingUrl?: string): void {
    productLandingUrl ??= this.getLinkedProduct().productLandingUrl; // equivalent to ||= in ruby
    if (productLandingUrl) {
      this.socialBlastParams.content = this.socialBlastParams.content.replace(productLandingUrl, "");
    }
  }

  // linked products must be alone so the first attachment is the linked product
  getLinkedProduct(): SocialBlastAttachment {
    return this.socialBlastParams.socialBlastAttachmentsAttributes[0];
  }

  private handleErrorResponse(response: any) {
    this.errors = [...new Set(this.errors.concat(this.errorService.extractErrorMessages(response)))];
  }

  private hasSelectedAnAccount(): boolean {
    return (this.socialBlastParams.useProviderFacebook
      || this.socialBlastParams.useProviderTwitter
      || this.socialBlastParams.useProviderInstagram);
  }

  private deleteAttachedProduct(): void {
    const previousProductIndex = this.attachedFiles.findIndex(({productId}) => productId);
    if (previousProductIndex > -1) {
      this.deleteAttachment(previousProductIndex);
    }
  }

  private validateProductImage(fileSize): void {
    this.errors = [];
    const maxImageSizeInKb = 5000;
    if (fileSize > maxImageSizeInKb * 1000) {
      this.displayErrors([`Product Image file size must be less than ${maxImageSizeInKb} Kb`]);
    }
  }

  private displayErrors(errors: string[] | string) {
    this.errors = this.errors.concat(errors);
    this.posting = false;
    setTimeout(() => this.errors = [], 4000);
  }

  private progressTimer(): NodeJS.Timeout {
    return setInterval(() => {
      if (this.assetLoadProgress <= 90) { // for large files don't let it hit the end and hang there
        this.assetLoadProgress++;
      }
    }, 300);
  }

  private completeProgressTimer(progressTimer: NodeJS.Timeout): void {
    this.assetLoadProgress = 100;
    setTimeout(() => {
      clearTimeout(progressTimer);
      this.assetLoadProgress = 0;
    }, 500);
  }

  private killProgressTimer(progressTimer: NodeJS.Timeout): void {
    clearTimeout(progressTimer);
    this.assetLoadProgress = 0;
  }

  // performs all possible validations on a file prior to attachment
  // reduces the number of erroneous files saved to S3
  private validateFileForAttachment(file: File): Observable<boolean> {
    return this.socialBlastAttachmentService.preValidate(
      file,
      this.attachedFiles,
      this.getSocialToValidate(),
    );
  }

  private getSocialToValidate(): { facebook: boolean, twitter: boolean, instagram: boolean } {
    return {
      facebook: this.socialBlastParams.useProviderFacebook,
      twitter: this.socialBlastParams.useProviderTwitter,
      instagram: this.socialBlastParams.useProviderInstagram,
    };
  }
}
