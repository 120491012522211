import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Response } from "@models/response";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { catchError, mergeMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { environment } from "@environments/environment";
import { StoreNotification } from "@models/store_notification";

@Injectable({
  providedIn: "root",
})
export class StoreNotificationsService {
  constructor(
    private http: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  private base = `${environment.apiUrl}/v2/store_notifications`;

  index(params?: any): Observable<Response<StoreNotification[]>> {
    return this.http.get<Response<StoreNotification[]>>(this.base, {params}).pipe(
      mergeMap(async (response: any) => {
        const notifications = this.deserializeService.deserialize(response);
        return new Response(notifications, response.meta);
      }),
      catchError((error) => {
        // Handle the error or log it
        console.error('Error fetching notifications:', error);

        // Return a safe, empty value. Assuming 'Response' constructor can handle empty or null 'meta'
        return of(new Response([], null));
      })
    );
  }

  dismiss(notificationId: string): Observable<Response<any>> {
    return this.http.get<Response<any>>(`${this.base}/${notificationId}/dismiss`, {});
  }
}
