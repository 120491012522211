import {
  Component, Input,
} from "@angular/core";
import { PardotScheduleDemoModalComponent } from "@app/shared/components/pardot_schedule_demo_modal/pardot_schedule_demo_modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@models/store";
import { Router } from "@angular/router";
import {StorageService} from "@app/shared/services/storage.service";

@Component({
  selector: "to-do-list",
  styleUrls: ["./to_do_list.component.scss"],
  templateUrl: "./to_do_list.component.html",
})
export class ToDoListComponent {
  @Input()
  store: Store

  loading: boolean;
  checklistItems = [];
  firstEmail = null;

  constructor(
      private modalService: NgbModal,
      private storageService: StorageService,
  ) {}

  ngOnInit(): void {
    this.getChecklistItems();
  }

  clickPerformanceItem() {
    this.storageService.setItem("reviewMarketingPerformanceToDo", new Date().toISOString());
  }

  checklistItemClick(item, extraArgs = null) {
    // How to pass a param in the HTML:
    // (cta)="checklistItemClick(item, ['param'])"

    if (item.cta) {
      this[item.cta].apply(this, extraArgs);
    }
  }

  private getChecklistItems() {
    const today = new Date();
    let checklistItems = [];
    let lastEmail: any = this.store.milestones.lastEmailCampaignSentAt;

      if (!this.store.milestones.firstSignupFormActivatedAt) {
        checklistItems.push({
          id: "signForm",
          subtext: "Organically grow your newsletter signups with a website Pop Up or Embedded Form.",
          text: "Launch a Sign Up Form",
          ctaLink: "/forms",
          ctaText: "Activate form",
          cta: null,
          order: 1
        });
      }

      if (!this.store.milestones.firstAutomationActivatedAt) {
        checklistItems.push({
          id: "automation",
          subtext: "Build and fully customize automated messages to re-engage with shoppers.",
          text: "Activate An Automation",
          ctaLink: "/automations/flows",
          ctaText: "Activate Flow",
          cta: null,
          order: 2
        });
      }

      if (lastEmail) {
        lastEmail = lastEmail.toString().split("-");
        lastEmail = new Date(parseInt(lastEmail[0], 0), parseInt(lastEmail[1], 0) - 1, parseInt(lastEmail[2], 0));
      }
      if (!lastEmail || today.toDateString() !== lastEmail.toDateString()) {
        checklistItems.push({
          id: "sendEmail",
          subtext: "Engage with subscribers via email marketing and track Open, Click, and Deliverability Rates.",
          text: "Send An Email Campaign",
          ctaLink: "/email_campaigns/new/template",
          ctaText: "Create email",
          cta: null,
          order: 3
        });
      }

      const reviewMarketingPerformanceToDo = this.storageService.getItem("reviewMarketingPerformanceToDo");
      if (!reviewMarketingPerformanceToDo || today.toDateString() !== new Date(reviewMarketingPerformanceToDo).toDateString()) {
        checklistItems.push({
          id: "performance",
          subtext: "Get insight on how your channels and products are performing.",
          text: "Review Marketing Performance",
          ctaLink: "/performance/dashboard",
          ctaText: "Performance",
          cta: "clickPerformanceItem",
          order: 5
        });
      }

    checklistItems = checklistItems.sort((a, b) => a.order - b.order);
    const maxToShow = 3;
    checklistItems = checklistItems.splice(0, maxToShow);
    this.checklistItems = checklistItems;
  }
}
