<sb-breadcrumbs [links]="[{ label: 'Springbot Dashboard' }]"></sb-breadcrumbs>

<!-- Dashboard -->
<div class="fill-page homepage-background" *ngIf="!loading">
  <div *ngFor="let message of messages" [ngClass]="{ 'd-none' : numAlerts < 1 }">
    <ngb-alert
        [type]="message.type"
        (closed)="closeAlert()">
      {{ message.message }}
    </ngb-alert>
  </div>

  <sb-email-verification-alert *ngIf="!hideEmailVerificationAlert && showEmailVerificationAlert && userEmailAddress"
                               (close)="hideEmailVerificationAlert = true;showEmailVerificationAlert = false"
                               [emailAddress]="userEmailAddress">
  </sb-email-verification-alert>

  <div *ngIf="store && !showEmailVerificationAlert && store.platformConnected && showStoreSyncingAlert"
       class="warning-notification-banner blue-background fade show">
    <div class="row">
      <div class="col-md-9">
        <fa-icon [icon]="['fas', 'spinner']" spin="true" class="mr-2"></fa-icon>
        <span class="font-weight-bold">Store Data Syncing!</span>
        <span>
          We're gathering your data to empower your marketing!
        </span>
      </div>
      <div class="col-md-3 small text-right pr-4">Started {{ store.fullSyncStartedAt | date: 'short' }}</div>
    </div>
  </div>

  <div *ngIf="!store" class="text-center green-color p-5">
    <fa-icon [icon]="['fas', 'cog']" size="3x" [spin]="true"></fa-icon>
  </div>

  <div class="container-fluid pt-2 px-3" style="max-width: 1280px">
    <div class="text-right">
      <a *ngIf="showStepper && checklistOptOut" class="cursor-pointer" (click)="unhideStepper()">Show account setup checklist</a>
    </div>
    <sb-stepper [title]="'Account Setup'"
                [showPercentage]="showPercentage"
                [clearThreshold]="50"
                (hideStepper)="hideStepper()"
                [steps]="steps"
                *ngIf="store && showStepper && !checklistOptOut"
                class="animate-fade-in"
                (onClickStep)="onClickStepper($event)">
    </sb-stepper>

    <div class="row d-flex justify-content-center">
      <div class="col-lg-12 p-3">
        <section class="jumbotron mb-0 text-center bg-primary text-white" *ngIf="!store.milestones.firstEmailCampaignSentAt">
          <h1 class="display-4 text-white"><i class="fa fa-rocket"></i> Send Your First Email Campaign!</h1>
          <p class="lead catskill-white-color">Not sure how to start? Don't worry, we'll guide you every step of the way.</p>
          <button id="ec-bowling-alley__start" (click)="launchCampaignBowlingAlley()" class="btn btn-success btn-lg"><i class="fa fa-arrow-right"></i> Get Started!</button>
        </section>
        <div class="card hide-on-mobile" *ngIf="store.milestones.firstEmailCampaignSentAt">
          <div class="card-body">
            <to-do-list [store]="store"></to-do-list>
          </div>
        </div>
      </div>
      <div class="col-xl-6 p-3" style="max-height:600px">
        <div class="card subscriber-growth-chart-card" id="sb-dashboard__subscribers_growth-chart">
          <div class="card-body">
            <subscribers-chart (dateChanged)="datesChanged($event)"></subscribers-chart>
          </div>
        </div>
      </div>
      <div class="col-xl-6 p-3" style="max-height:600px">
        <channels-index-component [embedded]="true" [datesFilter]="datesFilter"></channels-index-component>
      </div>
    </div>
  </div>
</div>
