import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { getCSRFToken } from "@app/shared/services/csrf_token.service";
import { Response } from "@models/response";
import { Store } from "@models/store";
import { BehaviorSubject, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { BillingAccountService } from "@app/stores/services/billing_account.service";
import { StorageService } from "@app/shared/services/storage.service";
import { AuthTokenService } from "@app/auth/services/auth-token.service";

@Injectable({
  providedIn: "root",
})
export class StoreService {
  storeUrl = `${environment.apiUrl}/v2/stores`; // used for standard restful resources
  singularStoreUrl = `${environment.apiUrl}/v2/store`; // used for singular resource to access the current store
  private storeSource$ = new BehaviorSubject<Store>(null);

  private readonly headers: HttpHeaders;

  constructor(
      private http: HttpClient,
      private deserializeService: DeserializeService,
      private billingAccountService: BillingAccountService,
      private storageService: StorageService,
      private authTokenService: AuthTokenService,
  ) {
    this.headers = new HttpHeaders({
      Accept: "application/json",
      "X-CSRF-Token": getCSRFToken(),
    });
  }

  getStore(): Observable<Store> {
    return this.http.get<Response<any>>(this.singularStoreUrl).pipe(
      mergeMap(async (response) => {
        if (response &&  response.data) {
          let storesResponse = this.deserializeService.deserialize(response);
          const store = new Store(Object.assign({ id: storesResponse.id, name: storesResponse.name }, storesResponse));
          this.setStore(store);
          this.billingAccountService.initializeBilling();
          return store;
        }
        return null;
      })
    );
  }

  getStores(params): Observable<Response<Store[]>> {
    return this.http.get<any>(this.storeUrl, { params }).pipe(
      mergeMap(async (response) => {
        let storesResponse = this.deserializeService.deserialize(response);
        storesResponse = storesResponse.map((store) =>
          new Store(Object.assign({ id: store.id, name: store.name }, store))
        );
        return new Response(storesResponse, response.meta);
      })
    );
  }

  updateStore(params: Record<string, any> | Store = {}): Observable<Response<Store>> {
    return this.http
        .patch<Store>(this.singularStoreUrl, params).pipe(
            mergeMap(async (response) => {
              let storesResponse = this.deserializeService.deserialize(response);
              storesResponse = new Store(Object.assign({ id: storesResponse.id, name: storesResponse.name }, storesResponse));
              return new Response(storesResponse, response.meta);
            })
        );
  }

  create(params: any): any {
    return this.http.post(this.storeUrl, params);
  }

  updateEmailProvider(providerName): Observable<any> {
    // TODO Update to v2 endpoint
    const { headers } = this;
    return this.http.put<any>(
      "/newsletters_api/provider",
      { data: { provider: providerName } },
      { headers }
    );
  }

  setStore(store: Store): void {
    this.storageService.setItem("store-data-cached", new Date().toString());
    this.authTokenService.setCurrentStoreId(store.id);
    this.storeSource$.next(store);
  }

  clearStore(): void {
    this.storeSource$.next(null);
  }

  storeCacheExpired(): boolean {
    const cachedStr = this.storageService.getItem("store-data-cached");

    if (!cachedStr) {
      return true;
    }

    try {
      const cachedDate = new Date(cachedStr);
      const diff = Math.abs(new Date().getTime() - cachedDate.getTime());
      const diffMinutes = Math.floor((diff/1000)/60);
      return diffMinutes >= 2;
    } catch(e) {
      console.error(e);
      return true;
    }
  }

  getSource(): BehaviorSubject<Store> {
    return this.storeSource$;
  }
}
