<div class="sb-nav__wrapper text-center" *ngIf="showNavbar" (mouseleave)="mouseLeaveEvent()">
  <div class="sb-nav__link-wrapper">
    <div class="sb-nav__link cursor-pointer"
         [class.active-link]="linkActive('/')"
         (click)="navigate('/')">
      <img src="https://springbot-assets.s3.amazonaws.com/images/springbot-icon.png"
           class="sb-nav__sb-icon cursor-pointer">
    </div>
  </div>
  <div class="sb-navbar">
    <ng-container *ngFor="let link of links" class="pt-4">
      <div *ngIf="!link.hidden" ngbDropdown #dropdown
           [attr.id]="'sb-stepper__' + link.label.toLowerCase().split(' ').join('-') + '__dropdown'"
           class="sb-nav__link-wrapper mobile-responsive-link"
           (mouseenter)="hidingMenu = false">
        <div class="sb-nav__link cursor-pointer py-3"
             [ngClass]="{ 'cursor-not-allowed': link.disabled }"
             [class.active-link]="linkActive(link.url)"
             placement="right" [ngbTooltip]="link.disabled && link.label === 'Performance' ? performanceTipContent : ''"
             (click)="clickEvent(link)" (mouseenter)="mouseEnterEvent(link)" ngbDropdownToggle>
          <fa-icon [icon]="link.icon" [ngClass]="{ 'accent-gray-color': link.disabled }" size="2x" class="hide-on-mobile"></fa-icon>
          <fa-icon [icon]="link.icon" [ngClass]="{ 'accent-gray-color': link.disabled }" class="show-on-mobile"></fa-icon>
          <div class="mt-2" [ngClass]="{ 'accent-gray-color': link.disabled }">{{ link.label }}</div>
        </div>
        <div class="sb-nav__sub-menu py-4" [class.show]="hover === link.label" ngbDropdownMenu>
          <ng-container *ngFor="let link of link.children">
            <div *ngIf="!link.hidden">
              <a (click)="clickEvent(link)"
                 class="dropdown-item pl-4 py-3">
                <fa-icon [icon]="link.icon" *ngIf="link.icon" class="mr-1"></fa-icon>
                {{ link.label }}
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-template #performanceTipContent>Take action to review marketing performance!</ng-template>
  </div>
</div>
